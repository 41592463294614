import React, {useEffect, useState} from 'react'
import sessionManager from '../util/sessionManager'
import socketManager from '../util/socketManager'
import classNames from 'classnames'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import imageLogoceoh from '../images/logo-ceoh.png'
import Button from 'react-bootstrap/Button'
import * as buttonStyles from '../styles/button.module.css'
import Image from 'react-bootstrap/Image'

export default function CEOHResourceLibrary() {
  const [global, setGlobal] = useState(sessionManager.getState())
  const dispatch = sessionManager.dispatch


  useEffect(function () {
    return sessionManager.subscribe(function () {
      setGlobal(sessionManager.getState())
    })
  }, [])


  return (
    <main>
      <title>CE-OH! Resource Library</title>

      <NavBar global={global} dispatch={dispatch} />

      <div className='container'>
        <div className='row flex-lg-row-reverse align-items-center g-5 py-5'>
          <div className='col-10 col-sm-8 col-lg-6'>
            <img alt='C E O logo' className='img-fluid' src={imageLogoceoh} />
          </div>
          <div className='col-lg-6'>
            <h1 className='display-5 fw-bold lh-1 mb-3'>
              Business and Entrepreneurship Training
            </h1>
            <p className='lead'>
              CE-Oh! is a business and entrepreneurship course that helps people
              monetize their passion, skills, and talent.
            </p>
            <div className='d-grid gap-2 d-md-flex justify-content-md-start'>
              {/* <Button
                href='/learners'
                type='button'
                size='lg'
                className={classNames(
                  buttonStyles.button,
                  buttonStyles.buttonPrimary
                )}
              >
                Learners
              </Button>

              <Button
                href='/mentors'
                type='button'
                size='lg'
                className={classNames(buttonStyles.button)}
              >
                Mentors
              </Button> */}
            </div>
          </div>
        </div>
        <hr className='featurette-divider' />
        <p className='h1 display-4 fw-bold text-center'>Participants:</p>
        <div className='row featurette align-items-center'>
          <div className='col-md-7'>
            <h2 className='featurette-heading'>
              Have the tools to start their own business
            </h2>
            <p className='lead'>
              They learn what it takes to get started, generate value, and make
              good decisions.
            </p>
          </div>
          <div className='col-md-5'>
            <Image src='../images/front01.webp' roundedCircle fluid />
          </div>
        </div>

        <hr className='featurette-divider' />

        <div className='row featurette align-items-center'>
          <div className='col-md-7 order-md-2'>
            <h2 className='featurette-heading'>
              Can earn industry-recognized certifications
            </h2>
            <p className ='lead'>
              They learn from a curriculum designed to help participants earn
              Pearson Certiport Entrepreneurship and Small Business
              Certification.
            </p>
          </div>
          <div className='col-md-5 order-md-1'>
            <Image src='../images/front02.webp' roundedCircle fluid />
          </div>
        </div>

        <hr className='featurette-divider' />

        <div className='row featurette align-items-center'>
          <div className='col-md-7'>
            <h2 className='featurette-heading'>
              Are more employable and ready to work
            </h2>
            <p className='lead'>
              They gain foundational business, financial, and technology
              literacies that are transferable to nearly every profession.
            </p>
          </div>
          <div className='col-md-5'>
            <Image src='../images/front03.webp' roundedCircle fluid />
          </div>
        </div>

        <div className='px-4 pt-5 my-5 text-center'>
          <p className='h1'>
            START SMALL. DREAM BIG. <em>ACT NOW.</em>
          </p>
          <div className='col-lg-6 mx-auto'>
            <div className='d-grid gap-2 d-sm-flex justify-content-sm-center mb-5'>
              <Button
                href='/learners-access-open'
                type='button'
                size='lg'
                className={classNames(
                  buttonStyles.button,
                  buttonStyles.buttonPrimary
                )}
              >
                Learners
              </Button>
{/* 
              <Button
                href='/mentors'
                type='button'
                size='lg'
                className={classNames(buttonStyles.button)}
              >
                Mentors
              </Button> */}
            </div>
          </div>
        </div>
      </div>
      <div className='footer'>
        <Footer />
      </div>
    </main>
  );
}
